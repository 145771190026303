import { Carousel } from 'antd';
import React, { useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';
import loginApis from '../../apis/login.api';

const BannerLogin = () => {
    const [listLeftImg, setListLeftImg] = useState([]);
    const [listRightImg, setListRightImg] = useState([]);
    useEffect(() => {
        loginApis.getBanner({}).then((res) => {
            if (!res.data) return;
            const resJson = JSON.parse(res.data.d);
            setListLeftImg(resJson.listLeftImg);
            setListRightImg(resJson.listRightImg);
        });
    }, []);
    const showBanner = (listBanner) => {
        if (!listBanner || listBanner.length === 0) return null;
        return listBanner.map((item, index) => {
            if (item.link) {
                return (
                    <a
                        href={item.link}
                        title={item.title}
                        key={index}
                        target="_blank"
                    >
                        <img
                            src={`/CPanel/Images/QuangCao/${item.hinhanh}`}
                            alt={item.tieude}
                        />
                    </a>
                );
            }
            return (
                <img
                    src={`/CPanel/Images/QuangCao/${item.hinhanh}`}
                    alt={item.tieude}
                    key={index}
                />
            );
        });
    };
    return (
        <>
            <div id="left-banner" className="slider-quangcao" data-timer="3">
                <Carousel autoplay dots={false}>
                    {showBanner(listLeftImg)}
                </Carousel>
            </div>
            <div id="right-banner" className="slider-quangcao" data-timer="3">
                <Carousel autoplay dots={false}>
                    {showBanner(listRightImg)}
                </Carousel>
            </div>
        </>
    );
};

BannerLogin.propTypes = {};

export default BannerLogin;

document.querySelectorAll('react-banner-login').forEach((domContainer) => {
    const root = createRoot(domContainer);
    root.render(<BannerLogin />);
});
