import React, { lazy, Suspense, useState } from 'react';
import { createRoot } from 'react-dom/client';
const ExcelDebtPopup = lazy(() => import('./Popup'));

/**
 * Nút in công nợ
 */
const ButtonInExcelDebt = () => {
    const [isShow, setIsShow] = useState(false);

    const handleClick = () => {
        setIsShow(true);
    };

    const onClosePopup = () => {
        setIsShow(false);
    };

    return (
        <div className="old-wrap-btn">
            <button
                className="btn-old-ver export-excel-btn"
                onClick={handleClick}
                type="button"
            >
                Xuất báo cáo công nợ
            </button>

            {isShow ? (
                <Suspense>
                    <ExcelDebtPopup isShow={isShow} onClose={onClosePopup} />
                </Suspense>
            ) : null}
        </div>
    );
};

export default ButtonInExcelDebt;

document.querySelectorAll('button-print-excel-debt').forEach((domContainer) => {
    const root = createRoot(domContainer);
    root.render(<ButtonInExcelDebt />);
});
