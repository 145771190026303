import axiosService from '../../services/axiosService';

const BASE_URL = '/FK/thongkebaocaoPGD.aspx';
const LIST_URL = {
    GET_BY_DATE: `${BASE_URL}/GetByDate`,
    GET_MENU_REPORT: `${BASE_URL}/GetListMenuReport`,
    UPDATE_BY_MONTH: `${BASE_URL}/UpdateByMonth`,
    UPDATE_CURRENT_MONTH: `${BASE_URL}/UpdateCurrentMonth`,
    UPDATE_SERVER: `${BASE_URL}/UpdateServer`,
    GET_QUANG_ICH_RESULT: `${BASE_URL}/GetQuangIchResult`,
};

class _fkThongKeBaoCaoApis {
    /**
     * Lấy thông tin báo cáo theo ngày
     */
    getByDate(dataPost) {
        return axiosService.post(LIST_URL.GET_BY_DATE, { ...dataPost });
    }

    /**
     * Lấy danh sách thực đơn cần báo cáo
     * @param {*} dataPost
     * @returns
     */
    getMenuReport(dataPost) {
        return axiosService.post(LIST_URL.GET_MENU_REPORT, {
            dataPost: dataPost,
        });
    }

    /**
     * Cập nhật dữ liệu cả tháng
     * @param {object} dataPost
     * @param {int} dataPost.Nam : Năm
     * @param {int} dataPost.Thang : Tháng
     * @returns
     */
    updateByMonth(dataPost) {
        return axiosService.post(LIST_URL.UPDATE_BY_MONTH, {
            dataPost: dataPost,
        });
    }

    updateCurrentMonth() {
        return axiosService.post(LIST_URL.UPDATE_CURRENT_MONTH, {});
    }

    updateServer() {
        return axiosService.post(LIST_URL.UPDATE_SERVER, {});
    }

    getQuangIchResult(messageId) {
        return axiosService.post(LIST_URL.GET_QUANG_ICH_RESULT, { messageId });
    }
}

export default new _fkThongKeBaoCaoApis();
