const getApiLinkByEnv = (localUrl, deployUrl) => {
    if (process.env.NODE_ENV === 'production') {
        return deployUrl;
    }
    return localUrl;
};

export const API_SCV1_ENDPOINT = getApiLinkByEnv(
    //'http://localhost:5160/api',
    'https://scv1-api.sc.edu.vn/api',
    'https://scv1-api.sc.edu.vn/api'
);

export const API_WEB_IMAGE_UPLOAD_ENDPOINT = getApiLinkByEnv(
    //'http://localhost:9005/api',
    'https://api.sc.edu.vn/api',
    'https://api.sc.edu.vn/api'
);
