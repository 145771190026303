import React, { useEffect, useState } from 'react';
import { FOODKID } from '../../../constants/foodkid.const';
import bangDieuTraApis from '../../../apis/foodkid/bangDieuTra.api';
import _thongKeBaoCaoPGDHelpers from '../ThongKeBaoCaoPGD/thongKeBaoCaoPGD.helpers';

/**
 * SỔ TRẺ THỰC ĂN - BÁO CÁO SỞ GIÁO DỤC
 */
const SoTreThucAnReportForm = () => {
    const [selectedId, setSelectedId] = useState(-1);
    const [selectedItem, setSelectedItem] = useState();
    const [reloadCount, setReloadCount] = useState(0);

    const doReset = () => {
        setSelectedItem();
    };

    const fechData = async () => {
        if (selectedId <= 0) return;
        const { status, data } = await bangDieuTraApis.getById(selectedId);
        if (status !== 200) {
            doReset();
            return;
        }
        const dataRes = JSON.parse(data.d);
        if (!dataRes.Success) {
            return;
        }
        setSelectedItem(dataRes.Data);
    };

    useEffect(() => {
        const elmTrigger = document.getElementById('reactTrigger');
        if (!elmTrigger) return;
        elmTrigger.addEventListener('click', function () {
            const elmMaSoTreThucAn = document.getElementById('hidMaBDT');
            if (!elmMaSoTreThucAn) return;
            const maSTTA = elmMaSoTreThucAn.getAttribute('value');
            setSelectedId(maSTTA);
            setReloadCount(window.reloadCount);
        });
    }, []);

    useEffect(() => {
        if (selectedId <= 0) {
            doReset();
            return;
        }
        fechData();
    }, [selectedId]);

    useEffect(() => {
        if (!reloadCount) return;
        fechData();
    }, [reloadCount]);

    const doReport = async () => {
        if (!selectedItem) return null;
        await _thongKeBaoCaoPGDHelpers.sendReport(
            selectedItem,
            FOODKID.MAU_BAO_CAO.SO_THUC_AN,
            () => {
                fechData();
            }
        );
    };

    const onGetResult = async () => {
        if (!selectedItem) return null;
        await _thongKeBaoCaoPGDHelpers.getResult(
            selectedItem,
            FOODKID.MAU_BAO_CAO.SO_THUC_AN,
            () => {
                fechData();
            }
        );
    };

    const showHtml = () => {
        if (!selectedItem) return null;
        return _thongKeBaoCaoPGDHelpers.showStamp(
            selectedItem,
            FOODKID.MAU_BAO_CAO.SO_THUC_AN,
            () => {
                doReport();
            },
            () => {
                onGetResult();
            }
        );
    };

    return <>{showHtml()}</>;
};

export default SoTreThucAnReportForm;
