import moment from 'moment';

const _datetimeUtils = {
    isDate(value) {
        return value instanceof Date && !isNaN(value.valueOf());
    },
    convetIntToDate(intDate, formatStr = 'DD/MM/YYYY') {
        return moment.unix(intDate).format(formatStr);
    },
    getUnixEpoch(date) {
        if (!this.isDate(date)) return 0;
        return moment(date).unix();
    },
    formatDatetime(date, format) {
        if (!format) {
            format = 'DD/MM/YYYY';
        }
        return moment.utc(date).format(format);
    },
};
export default _datetimeUtils;
