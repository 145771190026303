import React, { lazy } from 'react';
import PropTypes from 'prop-types';
import { createRoot } from 'react-dom/client';

const Container = lazy(() => import('./Container'));

const IcReportByCost = (props) => {
    return (
        <div className="sc-wrap react-css">
            <div className="page-title">Thống kê theo chi phí</div>
            <Container />
        </div>
    );
};

IcReportByCost.propTypes = {};

export default IcReportByCost;

document.querySelectorAll('react-ic-report-by-cost').forEach((domContainer) => {
    const root = createRoot(domContainer);
    root.render(<IcReportByCost />);
});
