import axiosService from '../../services/axiosService';

import globalAxiosService from '../../services/globalAxiosService';

const BASE_URL = '/FK/khoiphucdulieu.aspx';
const LIST_URL = {
    GET_MENU: `${BASE_URL}/GetMenuData`,
    RESTORE_MENU: 'http://localhost:5160/api/v1/ThucDon/HoiPhucThucDon',
};

class _fkKhoiPhucDuLieuApis {
    /**
     * Khôi phục dữ liệu Thực đơn
     */
    getMenu(dataPost) {
        return axiosService.post(LIST_URL.GET_MENU, { dataPost });
    }
    retoreMenu(dataPost) {
        return globalAxiosService.post(LIST_URL.RESTORE_MENU, dataPost);
    }
}

export default new _fkKhoiPhucDuLieuApis();
