import React from 'react';
import PropTypes from 'prop-types';
import { Card, Divider } from 'antd';

const FKThongKeBaoCaoPGDGuide = (props) => {
    return (
        <Card title={<b>HƯỚNG DẪN</b>} type="inner">
            <Divider orientation="left">
                <b style={{ color: '#0A9928' }}>ĐÃ BÁO CÁO</b>
            </Divider>
            <p>
                - Là sổ đã được báo cáo thành công lên PGD/SGD, khi bấm vào sẽ
                chuyển tới link báo cáo
            </p>

            <Divider orientation="left">
                <b style={{ color: 'orange' }}>ĐANG XỬ LÝ</b>
            </Divider>
            <p>
                - Là sổ đã báo cáo lên PGD/SGD nhưng đang chờ kết quả xử lý từ
                trục CSDL ngành.
            </p>
            <p>
                - Nếu gặp trạng thái này xin vui lòng đợi 2 phút sau đó bấm vào
                nút <b style={{ color: 'orange' }}>ĐANG XỬ LÝ</b> hoặc{' '}
                <b style={{ color: '#0069d9' }}>
                    GỬI BÁO CÁO VÀ CẬP NHẬT DỮ LIỆU
                </b>{' '}
                để cập nhật lại kết quả báo cáo
            </p>

            <Divider orientation="left">
                <b style={{ color: '#D23' }}>CHƯA BÁO CÁO</b>
            </Divider>

            <p>
                - Là sổ chưa được báo cáo lên PGD/SGD.
                <br /> <b>Nguyên nhân:</b> có thể do lỗi đường truyền hoặc lỗi
                hệ thống khi trường tạo sổ dẫn đến không báo cáo được tại thời
                điểm đó.
                <br /> Vì vậy để báo cáo quý thầy cô vui lòng <b>
                    bấm vào nút
                </b>{' '}
                <b style={{ color: '#D23' }}>CHƯA BÁO CÁO</b> hoặc{' '}
                <b style={{ color: '#0069d9' }}>
                    GỬI BÁO CÁO VÀ CẬP NHẬT DỮ LIỆU
                </b>{' '}
                ở bảng bên cạnh để thực hiện thao tác <b>báo cáo lên PGD/SGD</b>
                <br />
            </p>

            <Divider></Divider>

            <p>
                {' '}
                <b>---</b> : Là ngày nghỉ hoặc chưa lập sổ
            </p>
        </Card>
    );
};

FKThongKeBaoCaoPGDGuide.propTypes = {};

export default FKThongKeBaoCaoPGDGuide;
