import React, { useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';
import Stamp from '../../_Shares/Stamp';
import { STAMP } from '../../../utils/stamp.utils';
import foodkidMenuApis from '../../../apis/foodkid/foodkidMenu.api';
import { FOODKID } from '../../../constants/foodkid.const';
import alertUtils, { SWAL } from '../../../utils/alert.utils';
import datetimeUtils from '../../../utils/datetime.utils';
import _thongKeBaoCaoPGDHelpers from '../ThongKeBaoCaoPGD/thongKeBaoCaoPGD.helpers';

/**
 * THỰC ĐƠN - BÁO CÁO SỞ GIÁO DỤC
 */
const MenuReportForm = () => {
    const [menuId, setMenuId] = useState(-1);
    const [foodkidMenu, setFoodkidMenu] = useState();
    //const [reloadCount, setReloadCount] = useState(0);

    const doReset = () => {
        setFoodkidMenu();
    };

    const fechData = async () => {
        if (menuId <= 0) return;
        const { status, data } = await foodkidMenuApis.getById(menuId);
        if (status !== 200) {
            doReset();
            return;
        }
        const dataRes = JSON.parse(data.d);
        if (!dataRes.Success) {
            return;
        }

        setFoodkidMenu(dataRes.Data);
    };

    useEffect(() => {
        const elmTrigger = document.getElementById('reactTrigger');
        if (!elmTrigger) return;
        elmTrigger.addEventListener('click', function () {
            const elmMaThucDon = document.getElementById('hidMaThucDon');
            if (!elmMaThucDon) return;
            const maThucDon = elmMaThucDon.getAttribute('value');
            setMenuId(maThucDon);
            //setReloadCount(window.reloadCount);
        });
    }, []);

    useEffect(() => {
        if (menuId < 0) {
            doReset();
            return;
        }
        fechData();
    }, [menuId]);

    const doReport = async () => {
        await _thongKeBaoCaoPGDHelpers.sendReport(
            foodkidMenu,
            FOODKID.MAU_BAO_CAO.THUC_DON,
            () => {
                fechData();
            }
        );
    };

    const onGetResult = async () => {
        await _thongKeBaoCaoPGDHelpers.getResult(
            foodkidMenu,
            FOODKID.MAU_BAO_CAO.THUC_DON,
            () => {
                fechData();
            }
        );
    };

    const showHtml = () => {
        return _thongKeBaoCaoPGDHelpers.showStamp(
            foodkidMenu,
            FOODKID.MAU_BAO_CAO.THUC_DON,
            () => {
                doReport();
            },
            () => {
                onGetResult();
            }
        );
    };

    return <>{showHtml()}</>;
};

export default MenuReportForm;
