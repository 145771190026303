import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import AntdLocaleProvider from '../Common/Provider/AntdProvider/LocaleProvider';

const propTypes = {
    // Giá trị giới hạn mặc định
    defaultRange: PropTypes.number,

    // Giá trị giới hạn
    limitRange: PropTypes.number,

    // Loại giới hạn (days, week, month)
    rangeType: PropTypes.number,

    // Sự kiện thay đổi
    onChange: PropTypes.func,
    renderCount: PropTypes.number,
};

const SCRangeDate = ({
    defaultRange,
    limitRange,
    onChange,
    defaultStartDate,
    defaultEndDate,
    renderCount,
}) => {
    // Giá trị giới hạn mặc định
    defaultRange = defaultRange || 1;

    // Giá trị giới hạn
    limitRange = limitRange || 12;

    // Kiểu giới hạn (day, week, month)
    const rangeTypeStr = 'month';

    const [objRangDate, setObjRangeDate] = useState({
        startDate: dayjs(new Date()).startOf(rangeTypeStr),
        endDate: dayjs(new Date())
            .startOf(rangeTypeStr)
            .add(defaultRange, rangeTypeStr)
            .add(-1, 'millisecond'),
    });

    useEffect(() => {
        if (defaultEndDate) {
            setObjRangeDate({
                ...objRangDate,
                endDate: defaultEndDate,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [renderCount]);

    useEffect(() => {
        if (defaultStartDate && defaultEndDate) {
            setObjRangeDate({
                ...objRangDate,
                startDate: defaultStartDate,
                endDate: defaultEndDate,
            });
            return;
        }
        if (defaultStartDate) {
            setObjRangeDate({
                ...objRangDate,
                startDate: defaultStartDate,
            });
            return;
        }
        if (defaultEndDate) {
            setObjRangeDate({
                ...objRangDate,
                endDate: defaultEndDate,
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [renderCount]);

    useEffect(() => {
        if (onChange) {
            onChange({
                startDate: dayjs(objRangDate.startDate).startOf('day').toDate(),
                endDate: dayjs(objRangDate.endDate).endOf('day').toDate(),
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [objRangDate]);

    const handleEndDateChange = (s) => {
        // Ngày được chọn nhỏ hơn ngày bắt đầu
        if (s <= objRangDate.startDate) {
            setObjRangeDate({
                ...objRangDate,
                endDate: s,
                startDate: dayjs(s.startOf('day')).subtract(1, 'days'),
            });
        } else if (limitRange) {
            // Ngày chọn lớn hơn số giới hạn
            const _limitRangeDate = dayjs(
                objRangDate.startDate.startOf('day')
            ).add(limitRange, rangeTypeStr);

            if (_limitRangeDate < s) {
                setObjRangeDate({
                    ...objRangDate,
                    endDate: s,
                    startDate: dayjs(s.startOf('day')).subtract(
                        limitRange,
                        rangeTypeStr
                    ),
                });
            } else {
                setObjRangeDate({
                    ...objRangDate,
                    endDate: s,
                });
            }
        }

        if (onChange) {
            onChange({
                startDate: dayjs(objRangDate.startDate).startOf('day').toDate(),
                endDate: dayjs(objRangDate.endDate).endOf('day').toDate(),
            });
        }
    };

    const handleStartDateChange = (s) => {
        // Ngày được chọn lớn hơn ngày kết thúc
        if (s >= objRangDate.endDate) {
            setObjRangeDate({
                ...objRangDate,
                endDate: dayjs(s.startOf('day')).add(1, 'days'),
                startDate: s,
            });
        } else if (limitRange) {
            const _limitRangeDate = dayjs(
                objRangDate.endDate.startOf('day')
            ).subtract(limitRange, rangeTypeStr);

            if (_limitRangeDate > s) {
                setObjRangeDate({
                    ...objRangDate,
                    startDate: s,
                    endDate: dayjs(s.startOf('day')).add(
                        limitRange,
                        rangeTypeStr
                    ),
                });
            } else {
                setObjRangeDate({
                    ...objRangDate,
                    startDate: s,
                });
            }
        }

        if (onChange) {
            onChange({
                startDate: dayjs(objRangDate.startDate).startOf('day').toDate(),
                endDate: dayjs(objRangDate.endDate).endOf('day').toDate(),
            });
        }
    };

    return (
        <AntdLocaleProvider>
            <div className="row">
                <div className="col-sm-6">
                    <div className="my-label">Từ ngày</div>

                    <DatePicker
                        value={objRangDate.startDate}
                        format="DD/MM/YYYY"
                        onChange={(s) => handleStartDateChange(s)}
                        allowClear={false}
                    />
                </div>
                <div className="col-sm-6">
                    <div className="my-label">Đến ngày</div>

                    <DatePicker
                        value={objRangDate.endDate}
                        format="DD/MM/YYYY"
                        onChange={(s) => handleEndDateChange(s)}
                        allowClear={false}
                    />
                </div>
            </div>
        </AntdLocaleProvider>
    );
};

SCRangeDate.propTypes = propTypes;

export default SCRangeDate;
