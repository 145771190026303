import axios from 'axios';
import globalUtils from '../utils/global.utils';
import authenUtils from '../utils/authen.utils';

const getHeaders = (db) => {
    const headers = {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': '*',
        Authorization: `Bearer ${globalUtils.getCookie('sc_token')}`,
        matruong: authenUtils.getSchoolId(),
        db,
    };
    return headers;
};

const globalAxiosService = {
    get(url, db = '') {
        return this.doRequest('GET', url, '', db);
    },
    post(url, data, db = '') {
        return this.doRequest('POST', url, data, db);
    },

    put(url, data, db = '') {
        return this.doRequest('PUT', url, data, db);
    },

    delete(url, db = '') {
        return this.doRequest('DELETE', url, '', db);
    },

    doRequest(method, url, data, db) {
        const timeOut = 0;
        const headers = getHeaders(db);
        return axios({
            method,
            url,
            data,
            headers,
            timeout: timeOut,
        })
            .then((res) => res)
            .catch((err) => {
                const resp = err.response;
                if (!resp) {
                    return {
                        status: 503,
                        data: {
                            msg: 'Không kết nối được đến network',
                        },
                    };
                }
                switch (resp.status) {
                    case 404:
                    case 405:
                    case 500:
                    case 400: {
                        return resp;
                    }
                    case 401: {
                        return '';
                    }
                    default:
                        return resp;
                }
            });
    },
};

export default globalAxiosService;
