export const FOODKID = {
    IS_APPLY_REPORT: true,
    DO_TUOI: {
        SO_SINH: 1,
        BOT: 2,
        NHA_TRE: 3,
        MAU_GIAO: 4,
        TUOI_6_7: 5,
        TUOI_8_9: 6,
        TUOI_10_11: 7,
        KHAC: 8,
    },
    TRANG_THAI_BAO_CAO: {
        CHUA_LAM: -1,
        CHUA_BAO_CAO: 0,
        DANG_XU_LY: 10001,
        DA_BAO_CAO: 1,
    },
    MAU_BAO_CAO: {
        THUC_DON: 1,
        SO_KIEM_THUC: 2,
        SO_THUC_AN: 3,
        SO_TINH_TIEN_CHO: 4,
    },
};
