import React, { lazy } from 'react';
import { createRoot } from 'react-dom/client';
import { GLOBAL } from '../../../../constants/global.const';

const ClassNewsContainer = lazy(() => import('./Container'));

const ApprovedClassNewsPage = () => {
    return (
        <div className="sc-wrap react-css">
            <div className="page-title">Duyệt tin tức lớp</div>

            <ClassNewsContainer type={GLOBAL.APP_LOAI_TIN_TUC.LOP} />
        </div>
    );
};

export default ApprovedClassNewsPage;

document
    .querySelectorAll('react-app-approved-class-news')
    .forEach((domContainer) => {
        const root = createRoot(domContainer);
        root.render(<ApprovedClassNewsPage />);
    });
