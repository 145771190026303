import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import FKReportStatusButton from './StatusButton';
import { FOODKID } from '../../../constants/foodkid.const';

const FKThongKeBaoCaoPGDGridView = ({ dataSource, onReload, month }) => {
    const getColor = (dayOfWeek) => {
        switch (dayOfWeek) {
            case 'CN': {
                return 'red';
            }
            case 'T7': {
                return 'green';
            }
            default:
                return '';
        }
    };
    const columns = [
        {
            title: () => {
                return (
                    <b
                        style={{ fontSize: '14px' }}
                    >{`${month?.month}/${month?.year}`}</b>
                );
            },
            align: 'center',
            width: 80,
            render: (dataRow) => {
                const color = getColor(dataRow.ThuTrongTuan);

                return (
                    <span>
                        <b style={{ fontSize: '42px', color: color }}>
                            {' '}
                            {dataRow.Ngay}
                        </b>{' '}
                        <br></br>({dataRow.ThuTrongTuan})
                    </span>
                );
            },
        },
        {
            title: () => {
                return <b>THỰC ĐƠN</b>;
            },
            align: 'center',
            width: 200,
            render: (dataRow) => {
                return (
                    <FKReportStatusButton
                        dataSource={dataRow}
                        onReload={onReload}
                        reportType={FOODKID.MAU_BAO_CAO.THUC_DON}
                    />
                );
            },
        },
        {
            title: () => {
                return <b>SỔ KIỂM THỰC BA BƯỚC</b>;
            },
            align: 'center',
            width: 200,
            render: (dataRow) => {
                return (
                    <FKReportStatusButton
                        dataSource={dataRow}
                        onReload={onReload}
                        reportType={FOODKID.MAU_BAO_CAO.SO_KIEM_THUC}
                    />
                );
            },
        },
        {
            title: () => {
                return <b>SỔ THỰC ĂN</b>;
            },
            align: 'center',
            width: 200,
            render: (dataRow) => {
                return (
                    <FKReportStatusButton
                        dataSource={dataRow}
                        onReload={onReload}
                        reportType={FOODKID.MAU_BAO_CAO.SO_THUC_AN}
                    />
                );
            },
        },
        {
            title: () => {
                return <b>SỔ TÍNH TIỀN CHỢ</b>;
            },
            align: 'center',
            width: 200,
            render: (dataRow) => {
                return (
                    <FKReportStatusButton
                        dataSource={dataRow}
                        onReload={onReload}
                        reportType={FOODKID.MAU_BAO_CAO.SO_TINH_TIEN_CHO}
                    />
                );
            },
        },
    ];
    return (
        <Table
            dataSource={dataSource}
            columns={columns}
            pagination={false}
            bordered
            sticky
            className="table-fk-thong-ke-pgd"
            rowKey="Ngay"
        />
    );
};

FKThongKeBaoCaoPGDGridView.propTypes = {};

export default FKThongKeBaoCaoPGDGridView;
