import React from 'react';
import './_style.scss';
import { FOODKID } from '../../../constants/foodkid.const';

const Stamp = ({
    reportStatus,
    reportLink,
    funcReportCallback,
    funcGetResultCallback,
}) => {
    console.log(reportStatus);
    const showHtml = () => {
        switch (reportStatus) {
            case FOODKID.TRANG_THAI_BAO_CAO.CHUA_BAO_CAO: {
                return (
                    <span
                        onClick={() => {
                            if (funcReportCallback) {
                                funcReportCallback();
                            }
                        }}
                    >
                        <span className={`stamp is-nope`}>CHƯA BÁO CÁO</span>
                    </span>
                );
            }
            case FOODKID.TRANG_THAI_BAO_CAO.DANG_XU_LY: {
                return (
                    <span
                        onClick={() => {
                            if (funcGetResultCallback) {
                                funcGetResultCallback();
                            }
                        }}
                    >
                        <span className={`stamp is-processing`}>
                            ĐANG XỬ LÝ
                        </span>
                    </span>
                );
            }
            case FOODKID.TRANG_THAI_BAO_CAO.DA_BAO_CAO: {
                return (
                    <a href={reportLink} target="_blank">
                        <span className={`stamp is-approved`}>ĐÃ BÁO CÁO</span>
                    </a>
                );
            }
            default: {
                return <></>;
            }
        }
    };
    return <>{showHtml()}</>;
};

export default Stamp;
