import React, { useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';
import './_style.scss';
import ThongKeBaoCaoPGDContainer from './Container';
import _accountApis from '../../../apis/account/account.api';
import { Card } from 'antd';

const FKThongKeBaoCaoPGD = () => {
    const [isShow, setIsShow] = useState(false);
    const [userInfo, setUserInfo] = useState();

    useEffect(() => {
        _accountApis.getConnectInfo().then(({ status, data }) => {
            if (status != 200) return;
            if (!data.d) return;
            const dataRes = JSON.parse(data.d);
            if (dataRes.madinhdanhsgd && dataRes.mathanhpho == '02') {
                setIsShow(true);
            }
            setUserInfo(dataRes);
        });
    }, []);

    const showHtml = () => {
        if (!isShow) {
            let xmhml = null;
            if (!userInfo) {
                return null;
            }
            if (userInfo.mathanhpho != '02') {
                return (
                    <Card title={<b>THÔNG TIN</b>} type="inner">
                        <p>Tính năng chỉ dành cho các trường ở HCM</p>
                    </Card>
                );
            }
            return (
                <Card title={<b>THÔNG TIN</b>} type="inner">
                    <p>Trường chưa kết nối đến CSDL ngành của TP.HCM</p>
                </Card>
            );
        }
        return <ThongKeBaoCaoPGDContainer />;
    };
    return <>{showHtml()}</>;
};

export default FKThongKeBaoCaoPGD;

document
    .querySelectorAll('react-fk-thong-ke-bao-cao-pgd')
    .forEach((domContainer) => {
        const root = createRoot(domContainer);
        root.render(<FKThongKeBaoCaoPGD />);
    });
