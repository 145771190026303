import React, { useState } from 'react';
import NotificationBellDetailPopup from './DetailPopup';

const NotificationBellListView = ({ listNotif }) => {
    const [selectItem, setSelectItem] = useState(null);

    const onClose = () => {
        setSelectItem(null);
    };

    const showHtml = () => {
        if (listNotif?.length === 0) return null;
        return listNotif.map((item, i) => {
            return (
                <li
                    key={i}
                    onClick={() => {
                        setSelectItem(item);
                    }}
                >
                    {item.tenthongbao}
                </li>
            );
        });
    };
    return (
        <>
            <div className="title-notification">
                <span className="title">Thông báo</span>
                <ul className="my-ul">{showHtml()}</ul>
            </div>
            <NotificationBellDetailPopup
                selectItem={selectItem}
                onClose={onClose}
            ></NotificationBellDetailPopup>
        </>
    );
};

export default NotificationBellListView;
