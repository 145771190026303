import axiosService from '../../services/axiosService';

const BASE_URL = '/Controller/FK/ThucDonController.aspx';
const LIST_URL = {
    GET_BY_ID: `${BASE_URL}/GetById`,
    SEND_REPORT: `${BASE_URL}/SendReport`,
    SEND_LIST_REPORT: `${BASE_URL}/SendListReport`,
    UPDATE_SERVER: `${BASE_URL}/UpdateServerReport`,
    GET_TOTAL: `${BASE_URL}/GetTotal`,
    GET_RESULT: `${BASE_URL}/GetResult`,
};

const foodkidMenuApis = {
    getById(maThucDon) {
        const dataPost = {
            maThucDon,
        };

        return axiosService.post(LIST_URL.GET_BY_ID, { dataPost });
    },
    sendReport(dataReport) {
        return axiosService.post(LIST_URL.SEND_REPORT, {
            dataPost: dataReport,
        });
    },
    sendReport(maTruong, ngayLap, doTuoi) {
        return axiosService.post(LIST_URL.SEND_REPORT, {
            maTruong,
            ngayLap,
            doTuoi,
        });
    },
    getResult(maTruong, ngayLap, doTuoi) {
        return axiosService.post(LIST_URL.GET_RESULT, {
            maTruong,
            ngayLap,
            doTuoi,
        });
    },
    sendListReport(dataReport) {
        return axiosService.post(LIST_URL.SEND_LIST_REPORT, {
            listMenu: dataReport,
        });
    },
    updateServer(dataPost) {
        return axiosService.post(LIST_URL.UPDATE_SERVER, {
            dataPost: dataPost,
        });
    },
    getTotal() {
        return axiosService.post(LIST_URL.GET_TOTAL, {});
    },
};
export default foodkidMenuApis;
