import React, { useEffect, useState } from 'react';

import { Button, Modal } from 'antd';
import { Parser } from 'html-to-react';

const NotificationBellDetailPopup = ({ selectItem, onClose }) => {
    const [isShow, setIsShow] = useState(false);
    useEffect(() => {
        if (!selectItem) {
            setIsShow(false);
            return;
        }
        setIsShow(true);
    }, [selectItem]);
    return (
        <Modal
            title={selectItem?.tenthongbao}
            open={isShow}
            onCancel={onClose}
            width={1400}
            style={{ top: 0 }}
            footer={[
                <Button key="back" onClick={onClose}>
                    Đóng lại
                </Button>,
            ]}
        >
            {Parser().parse(selectItem?.noidung)}
        </Modal>
    );
};

NotificationBellDetailPopup.propTypes = {};

export default NotificationBellDetailPopup;
