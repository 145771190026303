import axiosService from '../../services/axiosService';

const BASE_URL = '/Controller/FK/BangDieuTraController.aspx';
const LIST_URL = {
    GET_BY_ID: `${BASE_URL}/GetById`,
    SEND_REPORT: `${BASE_URL}/SendReport`,
    SEND_REPORT_TYPE2: `${BASE_URL}/SendReportType2`,
    UPDATE_NULL_SCHOOLID: `${BASE_URL}/UpdateNullSchoolId`,
    UPDATE_SERVER: `${BASE_URL}/UpdateServer`,
    GET_RESULT: `${BASE_URL}/GetResult`,
};

const bangDieuTraApis = {
    getById(maBangDieuTra) {
        const dataPost = {
            maBangDieuTra,
        };

        return axiosService.post(LIST_URL.GET_BY_ID, { dataPost });
    },
    sendReport(dataReport) {
        return axiosService.post(LIST_URL.SEND_REPORT, {
            dataPost: dataReport,
        });
    },
    sendReportType2(ngayLap, maTruong) {
        return axiosService.post(LIST_URL.SEND_REPORT_TYPE2, {
            ngayLap,
            maTruong,
        });
    },
    getResult(maTruong, ngayLap, doTuoi) {
        return axiosService.post(LIST_URL.GET_RESULT, {
            maTruong,
            ngayLap,
            doTuoi,
        });
    },
    updateNullSchoolId() {
        return axiosService.post(LIST_URL.UPDATE_NULL_SCHOOLID, {});
    },

    updateServer() {
        return axiosService.post(LIST_URL.UPDATE_SERVER, {});
    },
};

export default bangDieuTraApis;
