import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Table } from 'antd';

const EInvoiceErrorPopup = ({ dataSource, title, onClose, isShow }) => {
    const columns = [
        {
            title: 'STT',
            width: 60,
            align: 'center',
            render: (_dataItem, _row, index) => <span>{index + 1}</span>,
        },
        {
            title: 'Số biên lai',
            key: 'sobienlai',
            dataIndex: 'sobienlai',
            width: 140,
            render: (_value) => {
                return <b>{_value}</b>;
            },
        },
        {
            title: 'Lí do',
            key: 'msg',
            dataIndex: 'msg',
        },
    ];
    return (
        <div>
            <Modal
                title={<b style={{ color: '#fd7e14' }}>{title}</b>}
                style={{ top: 20 }}
                open={isShow}
                onCancel={onClose}
                width={800}
                footer={[
                    <Button key="back" onClick={onClose}>
                        Đóng lại
                    </Button>,
                ]}
            >
                <Table
                    dataSource={dataSource}
                    columns={columns}
                    bordered
                    pagination={false}
                    rowKey="sobienlai"
                ></Table>
            </Modal>
        </div>
    );
};

EInvoiceErrorPopup.propTypes = {};

export default EInvoiceErrorPopup;
