import jwt from 'jwt-decode';
import globalUtils from './global.utils';

const _authenUtils = {
    getCurrentUser() {
        try {
            const token = globalUtils.getCookie('sc_token');

            if (!token) return {};
            if (this.checkExpriedToken(token)) {
                return jwt(token);
            }
            return {};
        } catch {
            return {};
        }
    },
    checkExpriedToken(token) {
        try {
            if (token) {
                const jwtDecode = jwt(token);
                const currentTime = Math.floor(new Date().getTime() / 1000);

                if (jwtDecode.exp > currentTime) {
                    return true;
                }
            }
            return false;
        } catch {
            return false;
        }
    },
    getSchoolId() {
        const user = this.getCurrentUser();
        return user?.matruong;
    },
};

export default _authenUtils;
