import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { createRoot } from 'react-dom/client';
import './_style.scss';
import _alertUtils, { SWAL } from '../../utils/alert.utils';
import _eInvoiceApis from '../../apis/income/eInvoice.api';
import ErrorPopup from './ErrorPopup';

const propTypes = {
    invoiceType: PropTypes.number,
};

const ExportInvoiceButton = ({ invoiceType }) => {
    const [errorList, setErrorList] = useState([]);
    const [showErr, setShowErr] = useState(false);
    // Lấy hàm lấy dữ liệu từ file component được gọi
    const getInvoiceInfo = window.sendListId;

    useEffect(() => {
        return () => {
            setErrorList([]);
            setShowErr(false);
        };
    }, []);

    const doExport = async (listInvoice) => {
        const { data, status } = await _eInvoiceApis.doExport({
            list: listInvoice,
        });
        if (status !== 200) {
            _alertUtils.show(SWAL.TYPE.ERR, 'Lỗi không thể kết nối đến server');
            return;
        }
        const dataJson = JSON.parse(data.d);
        if (dataJson.data.errorList.length === 0) {
            _alertUtils.show(
                SWAL.TYPE.SUCCESS,
                'Xuất hóa đơn điện tử thành công'
            );
            if (window.refreshList) {
                window.refreshList(invoiceType);
            }
            return;
        }
        _alertUtils.closeLoading();
        setErrorList(dataJson.data.errorList);
        setShowErr(true);
    };

    console.log('handleClick');

    const handleClick = async () => {
        if (!getInvoiceInfo) return;

        console.log('handleClick');

        const listInvoice = getInvoiceInfo(invoiceType);
        if (listInvoice.length === 0) {
            _alertUtils.show(
                SWAL.TYPE.WARNING,
                'Bạn chưa chọn biên lai cần xuất hóa đơn điện tử'
            );
            return;
        }
        _alertUtils.showLoading('Đang xuất hóa đơn điện tử...');
        await doExport(listInvoice);
    };

    const onCloseErrPopup = () => {
        setShowErr(false);
        window.refreshList(invoiceType);
    };
    return (
        <div className="old-wrap-btn">
            <button
                className="btn-old-ver export-invoice-btn"
                onClick={handleClick}
                type="button"
            >
                Xuất hóa đơn điện tử
            </button>
            {errorList.length > 0 ? (
                <ErrorPopup
                    dataSource={errorList}
                    isShow={showErr}
                    title="Danh sách biên lai không xuất được HĐĐT"
                    onClose={onCloseErrPopup}
                />
            ) : null}
        </div>
    );
};

ExportInvoiceButton.propTypes = propTypes;

export default ExportInvoiceButton;

// Dùng để khai báo tag khi nhúng ngoài website
document
    .querySelectorAll('react-export-invoice-btn')
    .forEach((domContainer) => {
        const type = parseInt(domContainer.dataset.type, 10);
        const root = createRoot(domContainer);
        root.render(<ExportInvoiceButton invoiceType={type} />);
    });
