import React from 'react';
import PropTypes from 'prop-types';
import './_style.scss';
import { createRoot } from 'react-dom/client';
import _alertUtils, { SWAL } from '../../utils/alert.utils';
import _eInvoiceApis from '../../apis/income/eInvoice.api';

const propTypes = {
    invoiceType: PropTypes.number,
};

const EInvoiceUpdateButton = ({ invoiceType }) => {
    const getListInvoice = window.sendListInvoiceId;

    const doUpdate = async (listInvoice) => {
        _alertUtils.showLoading('Đang cập nhật biên lai...');
        const { data, status } = await _eInvoiceApis.doUpdate({
            list: listInvoice,
        });

        if (status !== 200) {
            _alertUtils.show(SWAL.TYPE.ERR, 'Lỗi không kết nối đến server');
            return;
        }

        const dataJson = JSON.parse(data.d);
        if (dataJson.data.length === 0) {
            _alertUtils.show(
                SWAL.TYPE.SUCCESS,
                'Không có biên lai nào cần cập nhật'
            );
            return;
        }

        if (window.refreshList) {
            window.refreshList(invoiceType);
        }

        _alertUtils.show(SWAL.TYPE.SUCCESS, `Cập nhật biên lai thành công`);
    };

    const handleClick = () => {
        if (!getListInvoice) {
            _alertUtils.show(
                SWAL.TYPE.ERR,
                'Không lấy được thông tin biên lai'
            );
            return;
        }
        const listInvoice = getListInvoice(invoiceType);
        if (listInvoice.length === 0) {
            _alertUtils.show(
                SWAL.TYPE.WARNING,
                'Không có biên lai nào cần cập nhật'
            );
            return;
        }

        doUpdate(listInvoice);
    };
    return (
        <div className="old-wrap-btn">
            <button
                className="btn-old-ver update-invoice-btn"
                onClick={handleClick}
                type="button"
            >
                Cập nhật H.Đơn Đ.Tử
            </button>
        </div>
    );
};

EInvoiceUpdateButton.propTypes = propTypes;

export default EInvoiceUpdateButton;

document
    .querySelectorAll('react-update-invoice-btn')
    .forEach((domContainer) => {
        const type = parseInt(domContainer.dataset.type, 10);
        const root = createRoot(domContainer);
        root.render(<EInvoiceUpdateButton invoiceType={type} />);
    });
