import React from 'react';
import PropTypes from 'prop-types';
import _thongKeBaoCaoPGDHelpers from './thongKeBaoCaoPGD.helpers';

const FKReportStatusButton = ({ dataSource, onReload, reportType }) => {
    const onSendReport = async () => {
        await _thongKeBaoCaoPGDHelpers.sendReport(
            dataSource,
            reportType,
            () => {
                if (onReload) {
                    onReload();
                }
            }
        );
    };

    const onGetResult = async () => {
        await _thongKeBaoCaoPGDHelpers.getResult(dataSource, reportType, () => {
            onReload();
        });
    };

    const showResult = async (messageId) => {
        if (!messageId) return;
        await _thongKeBaoCaoPGDHelpers.showQuangIchResult(messageId);
    };

    const showHtml = () => {
        return _thongKeBaoCaoPGDHelpers.showStatusButton(
            dataSource,
            reportType,
            () => {
                onSendReport();
            },
            () => {
                onGetResult();
            },
            (messageId) => {
                showResult(messageId);
            }
        );
    };
    return <>{showHtml()}</>;
};

FKReportStatusButton.propTypes = {};

export default FKReportStatusButton;
