import axiosService from '../../services/axiosService';
import _globalAxiosService from '../../services/globalAxiosService';
import { API_SCV1_ENDPOINT } from '../../constants/api.const';

const BASE_URL = '/Controller/Share/AccountController.aspx';
const BASE_API_URL = `${API_SCV1_ENDPOINT}/v1/taikhoantruong`;
const LIST_URL = {
    GET_INFO: `${BASE_URL}/GetInfo`,
    GET_CONNECT_INFO: `${BASE_URL}/GetConnectInfo`,
    GET_OWN_LIST: `${BASE_API_URL}/LayDanhSachCuaTruong`,
};
class _accountApis {
    getInfo() {
        return axiosService.post(LIST_URL.GET_INFO, {});
    }

    getConnectInfo() {
        return axiosService.post(LIST_URL.GET_CONNECT_INFO, {});
    }

    getOwnList() {
        return _globalAxiosService.get(LIST_URL.GET_OWN_LIST);
    }
}

export default new _accountApis();
