import { Modal } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { createRoot } from 'react-dom/client';
import promotionApis from '../../../apis/common/promotion.api';
import cookieUtils from '../../../utils/cookie.utils';
import './_style.scss';
import htmlUtils from '../../../utils/html.utils';

const NotificationPopup = () => {
    const [promotion, setPromotion] = useState();
    const [isShow, setIsShow] = useState(false);
    const [classCssType, setClassCssType] = useState('');

    const imgUrl = useMemo(() => {
        if (!promotion) return '';
        return `https://www.sc.edu.vn/CPanel/Images/KhuyenMai/${promotion.hinhanh}`;
    }, [promotion]);

    const fetchData = async () => {
        const { status, data } = await promotionApis.getLogin();
        if (status !== 200) {
            return;
        }
        if (!data.data?.hienthikhidangnhap) {
            return;
        }
        if (data.data?.loaihienthi) {
            setClassCssType('img-type');
        }
        setPromotion(data.data);
        setIsShow(true);
        cookieUtils.set('showPromoPopup', '1', 1);
    };

    useEffect(() => {
        const promoCookie = cookieUtils.get('showPromoPopup');
        if (promoCookie == 1) return;
        fetchData();
    }, []);

    const handleClose = () => {
        setIsShow(false);
    };
    const showHtml = () => {
        if (!promotion) return null;
        if (!promotion?.hienthikhidangnhap) return null;
        if (promotion?.loaihienthi) {
            if (!promotion.link) return <img src={imgUrl}></img>;
            return (
                <a
                    href={promotion.link}
                    target="_blank"
                    title={promotion.tenkhuyenmai}
                >
                    <img src={imgUrl}></img>
                </a>
            );
        }

        let noidung = promotion.noidung;
        var m,
            urls = [],
            str = promotion.noidung,
            rex = /<img[^>]+src="?([^"\s]+)"?[^>]*\/>/g;

        while ((m = rex.exec(str))) {
            urls.push(m[1]);
        }
        if (urls.length > 0) {
            urls.forEach((elm) => {
                var regex = new RegExp(elm, 'g');
                noidung = noidung.replace(regex, `https://www.sc.edu.vn${elm}`);
            });
        }
        return htmlUtils.show(noidung);
    };
    return (
        <>
            <Modal
                title={''}
                open={isShow}
                onCancel={handleClose}
                className={`promotion-popup ${classCssType}`}
                footer={false}
                centered
                width={1000}
                maskClosable={false}
            >
                {showHtml()}
            </Modal>
        </>
    );
};

export default NotificationPopup;

document
    .querySelectorAll('react-notification-popup')
    .forEach((domContainer) => {
        const root = createRoot(domContainer);
        root.render(<NotificationPopup />);
    });
