export const GLOBAL = {
    // Xuất hóa đơn điện tử
    EXPORT_INVOCE: {
        // Theo danh sách lớp
        BY_CLASS: 1,
        // Theo danh sách ngày
        BY_DAY: 2,
    },
    IMAGE_RESIZE_CONFIG: {
        MAX_WIDTH: 1600,
        MAX_HEIGHT: 1600,
        MAX_HEIGHT_800: 800,
        QUALITY: {
            PERCENT_10: 10,
            PERCENT_20: 20,
            PERCENT_30: 30,
            PERCENT_40: 40,
            PERCENT_50: 50,
            PERCENT_60: 60,
            PERCENT_70: 70,
            PERCENT_80: 80,
            PERCENT_90: 90,
            PERCENT_100: 100,
        },
        ROTATION: {
            DEG_0: 0,
            DEG_90: 90,
            DEG_180: 180,
            DEG_270: 270,
            DEG_360: 360,
        },
        OUT_PUT_TYPE: { BASE64: 'base64', BLOB: 'blob', FILE: 'file' },
    },
    IMAGE_TYPE: {
        JPEG: 'JPEG',
        PNG: 'PNG',
        GIF: 'GIF',
        SVG: 'SVG',
        WEBP: 'WEBP',
    },
    APP_LOAI_TIN_TUC: {
        TRUONG: 1,
        LOP: 2,
        NOI_BO: 3,
    },
    IMAGE_CDN: 'https://cdn.sc.edu.vn/images/',
};
