import bangDieuTraApis from '../../../apis/foodkid/bangDieuTra.api';
import foodkidMenuApis from '../../../apis/foodkid/foodkidMenu.api';
import soKiemThucApis from '../../../apis/foodkid/soKiemThuc.api';
import soTinhTienChoApis from '../../../apis/foodkid/soTinhTienCho.api';
import _fkThongKeBaoCaoApis from '../../../apis/foodkid/thongKePGD.api';
import { FOODKID } from '../../../constants/foodkid.const';
import alertUtils, { SWAL } from '../../../utils/alert.utils';
import Stamp from '../../_Shares/Stamp';

const _thongKeBaoCaoPGDHelpers = {
    getStatus(reportStatus) {
        switch (reportStatus) {
            case FOODKID.TRANG_THAI_BAO_CAO.CHUA_LAM: {
                return <span>---</span>;
            }
            case FOODKID.TRANG_THAI_BAO_CAO.CHUA_BAO_CAO: {
                return (
                    <span className="table-stamp is-nope">Chưa báo cáo</span>
                );
            }
            case FOODKID.TRANG_THAI_BAO_CAO.DANG_XU_LY: {
                return (
                    <span className="table-stamp is-processing">
                        Đang xử lý
                    </span>
                );
            }
            case FOODKID.TRANG_THAI_BAO_CAO.DA_BAO_CAO: {
                return (
                    <span className="table-stamp is-approved">Đã báo cáo</span>
                );
            }
            default: {
                return <span></span>;
            }
        }
    },
    showStatusButton(
        dataSource,
        mauBaoCao,
        funcReportCallback,
        funcGetResultCallback,
        funcShowResultCallback
    ) {
        if (!mauBaoCao) return null;

        if (!dataSource) return null;

        const {
            TinhTrangThucDon,
            MaThucDon,
            TinhTrangSoBaBuoc,
            MaPhieuLuuHuyMau,
            TinhTrangBangDieuTra,
            MaBangDieuTra,
            TinhTrangSoTinhTienCho,
            MaSoTinhTienCho,
            DoTuoi,
            MaTruong,
            IntDate,
        } = dataSource;

        let reportStatus = '',
            linkReport = '',
            messageId = '';

        switch (mauBaoCao) {
            case FOODKID.MAU_BAO_CAO.THUC_DON: {
                messageId = MaThucDon;
                reportStatus = TinhTrangThucDon;
                linkReport = `https://foodkid-report.sc.edu.vn/report-v1/thuc-don?id=${MaTruong}-${DoTuoi}-${IntDate}`;
                break;
            }
            case FOODKID.MAU_BAO_CAO.SO_KIEM_THUC: {
                messageId = MaPhieuLuuHuyMau;
                reportStatus = TinhTrangSoBaBuoc;
                linkReport = `https://foodkid-report.sc.edu.vn/report-v1/so-kiem-thuc?id=${MaTruong}-${DoTuoi}-${IntDate}`;
                break;
            }
            case FOODKID.MAU_BAO_CAO.SO_THUC_AN: {
                messageId = MaBangDieuTra;
                reportStatus = TinhTrangBangDieuTra;
                linkReport = `https://foodkid-report.sc.edu.vn/report-v1/so-tre-thuc-an?id=${MaTruong}-${DoTuoi}-${IntDate}`;
                break;
            }
            case FOODKID.MAU_BAO_CAO.SO_TINH_TIEN_CHO: {
                messageId = MaSoTinhTienCho;
                reportStatus = TinhTrangSoTinhTienCho;
                linkReport = `https://foodkid-report.sc.edu.vn/report-v1/so-tinh-tien-cho?id=${MaTruong}-${DoTuoi}-${IntDate}`;
                break;
            }
        }

        const childHtml = this.getStatus(reportStatus);

        let clickCount = 0;
        let singleClickTimer = '';

        const handleSuccessClick = () => {
            clickCount++;
            if (clickCount === 1) {
                singleClickTimer = setTimeout(() => {
                    clickCount = 0;
                    window.open(linkReport);
                }, 200);
            } else if (clickCount === 2) {
                clearTimeout(singleClickTimer);
                clickCount = 0;
                if (funcShowResultCallback) {
                    funcShowResultCallback(messageId);
                }
            }
        };

        const handleProcessClick = () => {
            clickCount++;
            if (clickCount === 1) {
                singleClickTimer = setTimeout(() => {
                    clickCount = 0;
                    if (funcGetResultCallback) {
                        funcGetResultCallback();
                    }
                }, 200);
            } else if (clickCount === 2) {
                clearTimeout(singleClickTimer);
                clickCount = 0;
                if (funcShowResultCallback) {
                    funcShowResultCallback(messageId);
                }
            }
        };

        switch (reportStatus) {
            case FOODKID.TRANG_THAI_BAO_CAO.CHUA_BAO_CAO: {
                return (
                    <span
                        onClick={() => {
                            if (funcReportCallback) {
                                funcReportCallback();
                            }
                        }}
                    >
                        {childHtml}
                    </span>
                );
            }
            case FOODKID.TRANG_THAI_BAO_CAO.DANG_XU_LY: {
                return <span onClick={handleProcessClick}>{childHtml}</span>;
            }
            case FOODKID.TRANG_THAI_BAO_CAO.DA_BAO_CAO: {
                return <span onClick={handleSuccessClick}>{childHtml}</span>;
            }
            default: {
                return <>{childHtml}</>;
            }
        }
    },
    showStamp(
        dataSource,
        mauBaoCao,
        funcReportCallback,
        funcGetResultCallback
    ) {
        if (!mauBaoCao) return null;

        if (!dataSource) return null;

        const {
            TinhTrangThucDon,
            TinhTrangSoBaBuoc,
            TinhTrangBangDieuTra,
            TinhTrangSoTinhTienCho,
            DoTuoi,
            MaTruong,
            IntDate,
        } = dataSource;

        let reportStatus = '',
            linkReport = '';

        switch (mauBaoCao) {
            case FOODKID.MAU_BAO_CAO.THUC_DON: {
                reportStatus = TinhTrangThucDon;
                linkReport = `https://foodkid-report.sc.edu.vn/report-v1/thuc-don?id=${MaTruong}-${DoTuoi}-${IntDate}`;
                break;
            }
            case FOODKID.MAU_BAO_CAO.SO_KIEM_THUC: {
                reportStatus = TinhTrangSoBaBuoc;
                linkReport = `https://foodkid-report.sc.edu.vn/report-v1/so-kiem-thuc?id=${MaTruong}-${DoTuoi}-${IntDate}`;
                break;
            }
            case FOODKID.MAU_BAO_CAO.SO_THUC_AN: {
                reportStatus = TinhTrangBangDieuTra;
                linkReport = `https://foodkid-report.sc.edu.vn/report-v1/so-tre-thuc-an?id=${MaTruong}-${DoTuoi}-${IntDate}`;
                break;
            }
            case FOODKID.MAU_BAO_CAO.SO_TINH_TIEN_CHO: {
                reportStatus = TinhTrangSoTinhTienCho;
                linkReport = `https://foodkid-report.sc.edu.vn/report-v1/so-tinh-tien-cho?id=${MaTruong}-${DoTuoi}-${IntDate}`;
                break;
            }
        }

        return (
            <Stamp
                reportLink={linkReport}
                reportStatus={reportStatus}
                funcReportCallback={funcReportCallback}
                funcGetResultCallback={funcGetResultCallback}
            ></Stamp>
        );
    },
    /**
     * Kiểm tra dữ liệu khi đang ở trạng thái đang xử lý
     * @param {*} dataPost : dữ liệu báo cáo
     * @param {*} mauBaoCao : mẫu báo cáo
     * @param {*} funCallBack : function callback
     * @returns
     */
    async getResult(dataPost, mauBaoCao, funcCallback) {
        if (!mauBaoCao) return;
        if (!dataPost) return;

        const { MaTruong, IntDate, DoTuoi } = dataPost;

        alertUtils.showLoading('Đang cập nhật kết quả...');
        let isShowLoading = true;
        try {
            let res = null;
            switch (mauBaoCao) {
                case FOODKID.MAU_BAO_CAO.THUC_DON: {
                    res = await foodkidMenuApis.getResult(
                        MaTruong,
                        IntDate,
                        DoTuoi
                    );
                    break;
                }
                case FOODKID.MAU_BAO_CAO.SO_KIEM_THUC: {
                    res = await soKiemThucApis.getResult(
                        IntDate,
                        MaTruong,
                        DoTuoi
                    );
                    break;
                }
                case FOODKID.MAU_BAO_CAO.SO_THUC_AN: {
                    res = await bangDieuTraApis.getResult(
                        MaTruong,
                        IntDate,
                        DoTuoi
                    );
                    break;
                }
                case FOODKID.MAU_BAO_CAO.SO_TINH_TIEN_CHO: {
                    res = await soTinhTienChoApis.getResult(
                        MaTruong,
                        DoTuoi,
                        IntDate
                    );
                    break;
                }
            }

            if (!res) return;
            const { status, data } = res;
            if (status != 200) {
                alertUtils.show(
                    SWAL.TYPE.ERR,
                    'Không kết nối được với hệ thống'
                );
                return;
            }
            if (!data.d) {
                alertUtils.show(SWAL.TYPE.ERR, 'Xảy ra lỗi khi gửi báo cáo');
                return;
            }

            const resJson = JSON.parse(data.d);

            if (!resJson.Success) {
                alertUtils.show(SWAL.TYPE.ERR, res.Message);
                return;
            }

            const { isConfirmed, isDismissed } = await alertUtils.show(
                SWAL.TYPE.SUCCESS,
                'Cập nhật dữ liệu thành công!'
            );
            if (isConfirmed || isDismissed) {
                if (funcCallback) {
                    funcCallback();
                }
            }
            isShowLoading = false;
        } catch (err) {
            console.log(err);
        } finally {
            if (isShowLoading) {
                alertUtils.closeLoading();
            }
        }
    },

    /**
     * Gửi báo cáo lên PGD
     * @param {*} dataPost
     * @param {*} mauBaoCao
     * @param {*} funcCallback
     * @returns
     */
    async sendReport(dataPost, mauBaoCao, funcCallback) {
        if (!mauBaoCao) return;
        if (!dataPost) return;

        let tenBaoCao = '';
        switch (mauBaoCao) {
            case FOODKID.MAU_BAO_CAO.THUC_DON: {
                tenBaoCao = 'Thực đơn';
                break;
            }
            case FOODKID.MAU_BAO_CAO.SO_KIEM_THUC: {
                tenBaoCao = 'Sổ kiểm thực 3 bước';
                break;
            }
            case FOODKID.MAU_BAO_CAO.SO_THUC_AN: {
                tenBaoCao = 'Sổ thực ăn';
                break;
            }
            case FOODKID.MAU_BAO_CAO.SO_TINH_TIEN_CHO: {
                tenBaoCao = 'Sổ tính tiền chợ';
                break;
            }
        }
        const { Ngay, Thang, Nam, MaTruong, IntDate, DoTuoi } = dataPost;

        const { isConfirmed } = await alertUtils.show(
            SWAL.TYPE.QUESTION,
            `Bạn có muốn gửi báo cáo ${tenBaoCao} cho ngày ${Ngay}/${Thang}/${Nam} không ?`,
            true
        );
        if (!isConfirmed) return;

        alertUtils.showLoading('Đang gửi báo cáo lên PGD...');

        try {
            let res = null;
            switch (mauBaoCao) {
                case FOODKID.MAU_BAO_CAO.THUC_DON: {
                    res = await foodkidMenuApis.sendReport(
                        MaTruong,
                        IntDate,
                        DoTuoi
                    );
                    break;
                }
                case FOODKID.MAU_BAO_CAO.SO_KIEM_THUC: {
                    res = await soKiemThucApis.sendReportType2(
                        IntDate,
                        MaTruong
                    );
                    break;
                }
                case FOODKID.MAU_BAO_CAO.SO_THUC_AN: {
                    res = await bangDieuTraApis.sendReportType2(
                        IntDate,
                        MaTruong
                    );
                    break;
                }
                case FOODKID.MAU_BAO_CAO.SO_TINH_TIEN_CHO: {
                    res = await soTinhTienChoApis.sendReportType2(
                        IntDate,
                        MaTruong
                    );
                    break;
                }
            }
            if (!res) return;
            const { status, data } = res;
            if (status != 200) {
                alertUtils.show(
                    SWAL.TYPE.ERR,
                    'Không kết nối được với hệ thống'
                );
                return;
            }
            if (!data.d) {
                alertUtils.show(SWAL.TYPE.ERR, 'Xảy ra lỗi khi gửi báo cáo');
                return;
            }

            const resJson = JSON.parse(data.d);
            if (!resJson.Success) {
                alertUtils.show(SWAL.TYPE.ERR, resJson.Message);
                return;
            }

            const { isConfirmed, isDismissed } = await alertUtils.show(
                SWAL.TYPE.SUCCESS,
                'Báo cáo lên PGD thành công!'
            );

            if (isConfirmed || isDismissed) {
                if (funcCallback) {
                    funcCallback();
                }
            }
        } catch (err) {
            console.log(err);
            alertUtils.closeLoading();
        }
    },

    async showQuangIchResult(messageId) {
        alertUtils.showLoading('Đang kiểm tra dữ liệu...');
        let isShowLoading = true;
        try {
            const { status, data } =
                await _fkThongKeBaoCaoApis.getQuangIchResult(messageId);
            if (status !== 200) return;
            if (!data.d) return;
            isShowLoading = false;
            const text = `<pre style="text-align: left; font-family: Consolas">${JSON.stringify(
                {
                    MessageId: messageId,
                    ...JSON.parse(data.d),
                },
                undefined,
                2
            )}</pre>`;
            alertUtils.showText(text);
        } finally {
            if (isShowLoading) {
                alertUtils.closeLoading();
            }
        }
    },
};

export default _thongKeBaoCaoPGDHelpers;
