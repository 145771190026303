import React, { lazy, Suspense, useState } from 'react';
import { createRoot } from 'react-dom/client';
const ExcelRegisterCostPopup = lazy(() => import('./Popup'));

/**
 * Nút in danh sách chi phí thỏa thuận
 */
const ButtonIcPrintExcelRegisterCost = () => {
    const [isShow, setIsShow] = useState(false);

    const handleClick = () => {
        setIsShow(true);
    };

    const onClosePopup = () => {
        setIsShow(false);
    };

    return (
        <div className="old-wrap-btn">
            <button
                className="btn-old-ver export-excel-btn"
                onClick={handleClick}
                type="button"
            >
                Xuất Excel
            </button>
            {isShow ? (
                <Suspense>
                    <ExcelRegisterCostPopup
                        isShow={isShow}
                        onClose={onClosePopup}
                    />
                </Suspense>
            ) : null}
        </div>
    );
};

export default ButtonIcPrintExcelRegisterCost;

document
    .querySelectorAll('button-ic-print-excel-register-cost')
    .forEach((domContainer) => {
        const root = createRoot(domContainer);
        root.render(<ButtonIcPrintExcelRegisterCost />);
    });
