import React, { useEffect } from 'react';
import thongKePGDApi from '../../../apis/foodkid/thongKePGD.api';
import { createRoot } from 'react-dom/client';

const FKReportUpdateCurentMonth = () => {
    useEffect(() => {
        thongKePGDApi.updateCurrentMonth();
    }, []);
    return <></>;
};

export default FKReportUpdateCurentMonth;

document
    .querySelectorAll('react-fk-report-update-current-month')
    .forEach((domContainer) => {
        const root = createRoot(domContainer);
        root.render(<FKReportUpdateCurentMonth />);
    });
