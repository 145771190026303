import React, { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import bangDieuTraApis from '../../../apis/foodkid/bangDieuTra.api';

const SoThucAnUpdateForm = () => {
    useEffect(() => {
        bangDieuTraApis.updateNullSchoolId();
    }, []);
    return <></>;
};

export default SoThucAnUpdateForm;

document
    .querySelectorAll('react-so-tre-thuc-an-update-form')
    .forEach((domContainer) => {
        const root = createRoot(domContainer);
        root.render(<SoThucAnUpdateForm />);
    });
