import React, { useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';
import _notificationApis from '../../../apis/common/notification.api';
import _authenUtils from '../../../utils/authen.utils';
import NotificationBellListView from './ListView';

const NotificationBell = () => {
    const [listNotif, setListNotif] = useState([]);

    const fetchData = async () => {
        let user = _authenUtils.getCurrentUser();
        const { status, data } = await _notificationApis.getList(user);
        if (status === 200) {
            setListNotif(data.data);
        }
    };
    useEffect(() => {
        fetchData();
    }, []);

    const showHtml = () => {
        if (listNotif.length === 0) return null;
        return (
            <>
                <div className="main-notification">
                    <a className="icon-notif"></a>
                    <span className="info">{listNotif.length}</span>
                    <NotificationBellListView
                        listNotif={listNotif}
                    ></NotificationBellListView>
                </div>
            </>
        );
    };
    return <>{showHtml()}</>;
};

export default NotificationBell;

document.querySelectorAll('react-notification-bell').forEach((domContainer) => {
    const root = createRoot(domContainer);
    root.render(<NotificationBell />);
});
