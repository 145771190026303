import React, { lazy } from 'react';
import { createRoot } from 'react-dom/client';
import { GLOBAL } from '../../../constants/global.const';

const SchoolNewsContainer = lazy(() => import('./Container'));

const SchoolNewsPage = () => {
    return (
        <div className="sc-wrap react-css">
            <div className="page-title">Quản lý tin tức trường</div>

            <SchoolNewsContainer newsType={GLOBAL.APP_LOAI_TIN_TUC.TRUONG} />
        </div>
    );
};

export default SchoolNewsPage;

document.querySelectorAll('react-app-school-news').forEach((domContainer) => {
    const root = createRoot(domContainer);
    root.render(<SchoolNewsPage />);
});
