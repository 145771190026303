import { v4 as uuidv4 } from 'uuid';

const globalUtils = {
    setCookie(name, value, days) {
        var expires = '';
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
            expires = '; expires=' + date.toUTCString();
        }
        document.cookie = name + '=' + (value || '') + expires + '; path=/';
    },
    getCookie(name) {
        var nameEQ = name + '=';
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) == 0)
                return c.substring(nameEQ.length, c.length);
        }
        return null;
    },
    calculatePagesCount(pageSize, totalCount) {
        // we suppose that if we have 0 items we want 1 empty page
        return totalCount < pageSize ? 1 : Math.ceil(totalCount / pageSize);
    },
    buildFormData(data) {
        const formData = new FormData();
        Object.keys(data).forEach((key) => formData.append(key, data[key]));
        return formData;
    },
    randomUUID() {
        return uuidv4();
    },
    /**
     * Chèn kí tự
     * @param {*} value : Giá trị cần xử lý
     * @param {*} num : Số kí tự cần chèn
     * @param {*} char : kí tự chèn
     * @returns (2,2,0) => 02
     */
    padLeft(value, num, char) {
        return String(value).padStart(num, char);
    },
    nonAccentVietnamese(str) {
        if (!str) {
            return '';
        }
        str = str.toString().toLowerCase();
        str = str.replace(
            /\u00E0|\u00E1|\u1EA1|\u1EA3|\u00E3|\u00E2|\u1EA7|\u1EA5|\u1EAD|\u1EA9|\u1EAB|\u0103|\u1EB1|\u1EAF|\u1EB7|\u1EB3|\u1EB5/g,
            'a'
        );
        str = str.replace(
            /\u00E8|\u00E9|\u1EB9|\u1EBB|\u1EBD|\u00EA|\u1EC1|\u1EBF|\u1EC7|\u1EC3|\u1EC5/g,
            'e'
        );
        str = str.replace(/\u00EC|\u00ED|\u1ECB|\u1EC9|\u0129/g, 'i');
        str = str.replace(
            /\u00F2|\u00F3|\u1ECD|\u1ECF|\u00F5|\u00F4|\u1ED3|\u1ED1|\u1ED9|\u1ED5|\u1ED7|\u01A1|\u1EDD|\u1EDB|\u1EE3|\u1EDF|\u1EE1/g,
            'o'
        );
        str = str.replace(
            /\u00F9|\u00FA|\u1EE5|\u1EE7|\u0169|\u01B0|\u1EEB|\u1EE9|\u1EF1|\u1EED|\u1EEF/g,
            'u'
        );
        str = str.replace(/\u1EF3|\u00FD|\u1EF5|\u1EF7|\u1EF9/g, 'y');
        str = str.replace(/\u0111/g, 'd');
        // Some system encode vietnamese combining accent as individual utf-8 characters
        str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ''); // Huyen sac hoi nga nang
        str = str.replace(/\u02C6|\u0306|\u031B/g, ''); // A, O, U
        return str;
    },
    checkValueInList(idFieldName, idValue, listCheck) {
        let isExist = false;
        if (listCheck && listCheck.length > 0) {
            const objCheck = listCheck.find(
                (item) => item[idFieldName] === idValue
            );
            if (objCheck) {
                isExist = true;
            }
        }
        return isExist;
    },
};
export default globalUtils;
