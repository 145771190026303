import React, { lazy, Suspense, useRef, useState } from 'react';
import { createRoot } from 'react-dom/client';
import useEventListener from '../../../hooks/useEventListener';
const SystemHistoryPopup = lazy(() => import('./Popup'));

const SystemHistoryButton = () => {
    const [isShow, setIsShow] = useState(false);
    const [isShowPopup, setIsShowPopup] = useState(false);

    const keys = useRef({});

    const handleKeyPress = (event) => {
        const { keyCode, type } = event;
        const isKeyDown = type === 'keydown';
        keys.current[keyCode] = isKeyDown;

        /** Bấm Shift + S + C */
        if (
            type === 'keydown' &&
            keys.current[16] &&
            keys.current[83] &&
            keys.current[67]
        ) {
            setIsShow((prevState) => !prevState);
        }
    };

    useEventListener('keydown', handleKeyPress);
    useEventListener('keyup', handleKeyPress);

    const onOpenPopup = (_isShow) => {
        setIsShowPopup(_isShow);
    };

    return (
        <>
            {isShow ? (
                <div className="old-wrap-btn">
                    <button
                        className="btn-old-ver info-btn"
                        onClick={() => onOpenPopup(true)}
                        type="button"
                    >
                        Chi tiết
                    </button>

                    <Suspense>
                        <SystemHistoryPopup
                            isShow={isShowPopup}
                            onClose={() => onOpenPopup(false)}
                        />
                    </Suspense>
                </div>
            ) : null}
        </>
    );
};

SystemHistoryButton.propTypes = {};

export default SystemHistoryButton;

document
    .querySelectorAll('react-system-history-button')
    .forEach((domContainer) => {
        const root = createRoot(domContainer);
        root.render(<SystemHistoryButton />);
    });
