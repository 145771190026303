import React, { useEffect, useState } from 'react';
import { Select } from 'antd';

const { Option } = Select;

const KidGroupSelect = ({ onChange, title }) => {
    /** =================================================================
     * start state value
     ================================================================== */

    const [list] = useState([
        { id: 3, name: 'Nhà trẻ' },
        { id: 4, name: 'Mẫu giáo' },
    ]);

    const [selectId, setSelectId] = useState(3);

    useEffect(() => {
        if (onChange) {
            onChange(3);
        }
    }, []);

    /** =================================================================
     * end state value
     ================================================================== */

    const showItem = () => {
        if (!list || list.length === 0) {
            return null;
        }

        return list.map((item) => (
            <Option value={item.id} key={item.id}>
                {item.name}
            </Option>
        ));
    };

    const handleChange = (value) => {
        setSelectId(value);
        if (onChange) {
            onChange(value);
        }
    };

    return (
        <>
            {title ? <div className="my-label">{title}</div> : null}

            <Select value={selectId} onChange={handleChange}>
                {showItem()}
            </Select>
        </>
    );
};

export default KidGroupSelect;
