import React, { lazy, Suspense, useState } from 'react';
import { createRoot } from 'react-dom/client';

// import GCReportPopup from './Popup';
const GCReportPopup = lazy(() => import('./Popup'));
const GrowthChartReportPopup = () => {
    const [isShow, setIsShow] = useState(false);

    const handleClick = () => {
        setIsShow(true);
    };

    const onClosePopup = () => {
        setIsShow(false);
    };

    return (
        <div className="old-wrap-btn">
            <button
                className="btn-old-ver export-excel-btn"
                onClick={handleClick}
                type="button"
            >
                Xuất báo cáo file Excel
            </button>
            <Suspense>
                {isShow ? (
                    <GCReportPopup isShow={isShow} onClose={onClosePopup} />
                ) : null}
            </Suspense>
        </div>
    );
};

export default GrowthChartReportPopup;

document
    .querySelectorAll('growth-chart-report-popup')
    .forEach((domContainer) => {
        const root = createRoot(domContainer);
        root.render(<GrowthChartReportPopup />);
    });
