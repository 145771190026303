import axiosService from '../../services/axiosService';

const BASE_URL = '/Controller/FK/SoKiemThucBaBuocController.aspx';
const LIST_URL = {
    GET_BY_DATE: `${BASE_URL}/GetByDate`,
    SEND_REPORT: `${BASE_URL}/SendReport`,
    SEND_REPORT_TYPE2: `${BASE_URL}/SendReportType2`,
    UPDATE_SERVER: `${BASE_URL}/UpdateServer`,
    GET_RESULT: `${BASE_URL}/GetResult`,
};

const soKiemThucApis = {
    getByDate(ngayLap, maTruong) {
        const dataPost = {
            ngayLap,
            maTruong,
        };

        return axiosService.post(LIST_URL.GET_BY_DATE, { dataPost });
    },
    sendReport(listReport) {
        return axiosService.post(LIST_URL.SEND_REPORT, {
            listReport,
        });
    },
    sendReportType2(ngayLap, maTruong) {
        return axiosService.post(LIST_URL.SEND_REPORT_TYPE2, {
            ngayLap,
            maTruong,
        });
    },
    getResult(ngayLap, maTruong, doTuoi) {
        return axiosService.post(LIST_URL.GET_RESULT, {
            ngayLap,
            maTruong,
            doTuoi,
        });
    },
    updateServer() {
        return axiosService.post(LIST_URL.UPDATE_SERVER, {});
    },
};

export default soKiemThucApis;
