import React, { useEffect, useState } from 'react';
import KidGroupSelect from '../../_Shares/Select/KidGroup';
import alertUtils, { SWAL } from '../../../utils/alert.utils';
import FKThongKeBaoCaoPGDGuide from './Guide';
import MonthPicker from '../../_Shares/DateTimePicker/MonthPicker';
import GridView from './GridView';
import _fkThongKeBaoCaoApis from '../../../apis/foodkid/thongKePGD.api';
import foodkidMenuApis from '../../../apis/foodkid/foodkidMenu.api';
import globalUtils from '../../../utils/global.utils';
import soKiemThucApis from '../../../apis/foodkid/soKiemThuc.api';
import soTinhTienChoApis from '../../../apis/foodkid/soTinhTienCho.api';
import bangDieuTraApis from '../../../apis/foodkid/bangDieuTra.api';

const PAGE_SIZE = 20;
const ThongKeBaoCaoPGDContainer = () => {
    const [listReport, setListReport] = useState([]);
    const [yearInfo, setYearInfo] = useState();
    const [doTuoi, setDoTuoi] = useState();

    const fetchData = async (dataPost) => {
        alertUtils.showLoading('Đang lấy dữ liệu...');
        try {
            const res = await _fkThongKeBaoCaoApis.getByDate(dataPost);

            if (res.status != 200) {
                setListReport([]);
                return;
            }
            if (res.data.d) {
                const listData = JSON.parse(res.data.d);
                setListReport(listData);
            }
        } finally {
            alertUtils.closeLoading();
        }
    };
    useEffect(() => {
        if (!yearInfo || !doTuoi) return;
        fetchData({ ...yearInfo, doTuoi });
    }, [yearInfo, doTuoi]);

    const onMonthChange = (s) => {
        setYearInfo(s);
    };

    const onKidGroupChange = (s) => {
        setDoTuoi(s);
    };

    const onReload = () => {
        fetchData({ ...yearInfo, doTuoi });
    };

    // const onUpdateServer = async () => {
    //     try {
    //         alertUtils.showLoading('[1/4] Đang cập nhật thực đơn...');
    //         const dataPost = {
    //             currentPage: 1,
    //             pageSize: PAGE_SIZE,
    //         };
    //         await foodkidMenuApis.updateServer(dataPost);

    //         alertUtils.showLoading('[2/4] Đang cập nhật sổ ba bước...');
    //         await soKiemThucApis.updateServer();

    //         alertUtils.showLoading('[3/4] Đang cập nhật sổ tính tiền chợ...');
    //         await soTinhTienChoApis.updateServer();

    //         alertUtils.showLoading('[4/4] Đang cập nhật sổ trẻ thực ăn...');
    //         await bangDieuTraApis.updateServer();
    //     } finally {
    //         alertUtils.closeLoading();
    //     }
    // };

    // const onGetServerResult = async () => {
    //     alertUtils.showLoading('Đang cập nhật kết quả...');
    //     try {
    //         await _fkThongKeBaoCaoApis.updateServer();
    //     } catch (err) {
    //         console.log(err);
    //     } finally {
    //         alertUtils.closeLoading();
    //     }
    // };

    const onUpdateMonth = async () => {
        if (!yearInfo) return;
        const { year, month } = yearInfo;
        const swalRes = await alertUtils.show(
            SWAL.TYPE.QUESTION,
            `Bạn có muốn cập nhật dữ liệu cho cả tháng ${month}/${year} không?`,
            true
        );
        if (!swalRes.isConfirmed) return;
        alertUtils.showLoading(
            `Đang cập nhật dữ liệu cho tháng ${month}/${year}`
        );
        let isShowLoading = true;
        try {
            const { status, data } = await _fkThongKeBaoCaoApis.updateByMonth({
                thang: month,
                nam: year,
            });

            isShowLoading = false;
            if (status !== 200) {
                alertUtils.showErr('Không kết nối được đến hệ thống');
                return;
            }
            if (!data.d) {
                alertUtils.showErr('Không tìm thấy phản hồi của hệ thống');
                return;
            }
            const resJson = JSON.parse(data.d);
            if (!resJson.Success) {
                alertUtils.showErr(resJson.Message);
                return;
            }
            const resConfirm = await alertUtils.showSuccess(
                'Cập nhật thành công'
            );
            if (resConfirm.isConfirmed) {
                fetchData({ ...yearInfo, doTuoi });
            }
        } catch (err) {
            alertUtils.showErr(err);
        } finally {
            if (isShowLoading) {
                alertUtils.closeLoading();
            }
        }
    };

    return (
        <div className="sc-v2">
            <div className="card my-card">
                <div className="card-header">
                    <b>
                        Khẩu phần dinh dưỡng / Thống kê báo cáo Phòng Giáo Dục
                    </b>
                </div>
                <div className="card-body main-body no-footer">
                    <div className="col-sm-12">
                        <div className="row">
                            <div className="col-sm-3">
                                <MonthPicker
                                    title="Tháng"
                                    onChange={onMonthChange}
                                    disableDate={new Date(2023, 4, 1)}
                                />
                            </div>
                            <div className="col-sm-3">
                                <KidGroupSelect
                                    title="Nhóm trẻ"
                                    onChange={onKidGroupChange}
                                />
                            </div>

                            <div className="col-sm-3">
                                <div className="my-label">{''}</div>
                                <button
                                    className="btn btn-primary"
                                    onClick={onUpdateMonth}
                                    type="button"
                                >
                                    Gửi báo cáo và cập nhật dữ liệu
                                </button>
                                {/* <button
                                    className="btn btn-primary"
                                    onClick={onUpdateServer}
                                    type="button"
                                >
                                    Update
                                </button> */}

                                {/* <button
                                    className="btn btn-primary"
                                    onClick={onGetServerResult}
                                    type="button"
                                >
                                    Cập nhật kết quả hệ thống
                                </button> */}
                            </div>
                        </div>
                        <div className="cleafix mb-20"></div>
                        <div className="row">
                            <div className="col-sm-9">
                                <GridView
                                    dataSource={listReport}
                                    onReload={onReload}
                                    month={yearInfo}
                                />
                            </div>
                            <div className="col-sm-3">
                                <FKThongKeBaoCaoPGDGuide />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ThongKeBaoCaoPGDContainer;
