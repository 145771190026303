import axiosService from '../../services/globalAxiosService';

const LIST_URL = {
    GET_LIST: `https://scv1-api.sc.edu.vn/api/v1/webthongbao/laydanhsach`,
};

const notificationApis = {
    getList(dataPost) {
        return axiosService.post(LIST_URL.GET_LIST, dataPost, 'sc');
    },
};
export default notificationApis;
