import React, { useEffect, useRef, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { Button, Card } from 'antd';
import SCRangeDate from '../../components/_Shares/DateTimePicker/RangeDatePicker';
import { ReloadOutlined } from '@ant-design/icons';
import _fkKhoiPhucDuLieuApis from '../../apis/foodkid/foodkidKhoiPhucDuLieu.api';
import alertUtils from '../../utils/alert.utils';

const FKKhoiPhucDuLieu = () => {
    const rangeDate = useRef();
    const onDateChange = (s) => {
        rangeDate.current = s;
    };

    const doRestore = async () => {
        alertUtils.showLoading('Đang khôi phục...');
        const res = await _fkKhoiPhucDuLieuApis.getMenu({
            ...rangeDate.current,
        });
        if (res.status !== 200) return;
        const data = JSON.parse(res.data.d);
        console.log(data);
        const resRestore = await _fkKhoiPhucDuLieuApis.retoreMenu(data);
        if (resRestore.status === 200) {
            alertUtils.showSuccess('Hoàn thành');
        } else {
            alertUtils.closeLoading();
        }
    };

    return (
        <Card title={<b>KHÔI PHỤC DỮ LIỆU</b>}>
            <Card.Grid style={{ width: '50%' }}>
                <Card type="inner" title="Khôi phục thực đơn">
                    <div className="col-sm-12" style={{ padding: '20px' }}>
                        <SCRangeDate onChange={onDateChange} />
                    </div>
                    <div className="col-sm-12 text-center mb-20">
                        <Button
                            type="primary"
                            icon={<ReloadOutlined />}
                            onClick={doRestore}
                        >
                            Khôi phục
                        </Button>
                    </div>
                    <div className="clearfix"></div>
                </Card>
            </Card.Grid>
        </Card>
    );
};

export default FKKhoiPhucDuLieu;

document
    .querySelectorAll('react-fk-khoi-phuc-du-lieu')
    .forEach((domContainer) => {
        const root = createRoot(domContainer);
        root.render(<FKKhoiPhucDuLieu />);
    });
