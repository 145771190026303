import React, { useEffect, useMemo, useState } from 'react';
import Stamp from '../../_Shares/Stamp';
import { STAMP } from '../../../utils/stamp.utils';
import { FOODKID } from '../../../constants/foodkid.const';
import alertUtils, { SWAL } from '../../../utils/alert.utils';
import datetimeUtils from '../../../utils/datetime.utils';
import authenUtils from '../../../utils/authen.utils';
import soTinhTienChoApis from '../../../apis/foodkid/soTinhTienCho.api';
import _thongKeBaoCaoPGDHelpers from '../ThongKeBaoCaoPGD/thongKeBaoCaoPGD.helpers';

/**
 * SỔ TÍNH TIỀN CHỢ - BÁO CÁO SỞ GIÁO DỤC
 */
const SoTinhTienChoReportForm = () => {
    const [dataPost, setDataPost] = useState();
    const [listData, setListData] = useState([]);
    const [reloadCount, setReloadCount] = useState(0);

    const listNeedReport = useMemo(() => {
        if (listData.length === 0) return [];
        return listData.filter(
            (o) =>
                o.TinhTrangSoTinhTienCho !==
                    FOODKID.TRANG_THAI_BAO_CAO.CHUA_LAM &&
                o.TinhTrangSoTinhTienCho !==
                    FOODKID.TRANG_THAI_BAO_CAO.DA_BAO_CAO
        );
    }, [listData]);

    console.log(listData, listNeedReport);

    const doReset = () => {
        setListData([]);
    };

    const fechData = async () => {
        if (!dataPost) return;

        console.log(dataPost);
        const { status, data } = await soTinhTienChoApis.getByDate(
            dataPost?.ngayLap,
            dataPost?.maTruong
        );

        if (status !== 200) {
            doReset();
            return;
        }
        const dataRes = JSON.parse(data.d);
        if (!dataRes.Success) {
            doReset();
            return;
        }
        setListData(dataRes.Data);
    };

    useEffect(() => {
        const elmTrigger = document.getElementById('reactTrigger');
        if (!elmTrigger) return;
        elmTrigger.addEventListener('click', function () {
            const matruong = authenUtils.getSchoolId();
            setDataPost({ ngayLap: window.ngaylapSTTC, maTruong: matruong });
            setReloadCount(window.reloadCount);
        });
    }, []);

    useEffect(() => {
        if (!dataPost) {
            doReset();
            return;
        }

        fechData();
    }, [dataPost]);

    useEffect(() => {
        if (reloadCount) {
            fechData();
        }
    }, [reloadCount]);

    const doReport = async () => {
        if (!listData.length === 0) return null;
        await _thongKeBaoCaoPGDHelpers.sendReport(
            listData[0],
            FOODKID.MAU_BAO_CAO.SO_TINH_TIEN_CHO,
            () => {
                fechData();
            }
        );
    };

    const onGetResult = () => {
        if (!listData.length === 0) return null;
        listData.forEach(async (elm) => {
            await _thongKeBaoCaoPGDHelpers.getResult(
                elm,
                FOODKID.MAU_BAO_CAO.SO_TINH_TIEN_CHO,
                () => {
                    fechData();
                }
            );
        });
    };

    const showHtml = () => {
        if (!listData.length === 0) return null;
        const itemReport =
            listNeedReport.length === 0 ? listData[0] : listNeedReport[0];

        return _thongKeBaoCaoPGDHelpers.showStamp(
            itemReport,
            FOODKID.MAU_BAO_CAO.SO_TINH_TIEN_CHO,
            () => {
                doReport();
            },
            () => {
                onGetResult();
            }
        );
    };

    return <>{showHtml()}</>;
};

export default SoTinhTienChoReportForm;
