import { Parser } from 'html-to-react';

const _htmlUtils = {
    show(htmlContent) {
        return Parser().parse(htmlContent);
    },
    removeTag(htmlString) {
        if (!htmlString) return '';
        return htmlString.replace(/<\/?[^>]+(>|$)/g, '');
    },
    /** Xóa các attribute */
    removeAttrFromString(htmlString) {
        if (!htmlString) return '';
        const stringRes = htmlString
            .replace(/\s*\S*="[^"]+"\s*/gm, '')
            .replace(/&nbsp;/, '');

        return stringRes;
    },
    getListImgSrc(htmlString) {
        return (
            Array.from(
                new DOMParser()
                    .parseFromString(htmlString, 'text/html')
                    .querySelectorAll('img')
            ).map((img) => img.getAttribute('src')) ?? []
        );
    },
};

export default _htmlUtils;
