import React, { useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';
import './_style.scss';
import _accountApis from '../../../apis/account/account.api';

const ConnectInfo = () => {
    const [userInfo, setUserInfo] = useState();

    useEffect(() => {
        _accountApis.getConnectInfo().then(({ status, data }) => {
            if (status != 200) return;
            if (!data.d) return;
            const user = JSON.parse(data.d);
            if (user.madinhdanhsgd) {
                setUserInfo(user);
            }
        });
    }, []);

    const showHtml = () => {
        if (!userInfo) return null;

        return (
            <div className="connect-info ">
                <div className="shine-alt">
                    <div className="alert alert-success alert-styled-left alert-arrow-left alert-bordered">
                        Trường <b>{userInfo.tentruong}</b>
                        <br />
                        Mã CSDL: <b>{userInfo.madinhdanhsgd}</b>
                        <br />
                        Đã <b>kết nối</b> với <b>trục CSDL ngành TP.HCM</b>
                    </div>
                </div>
            </div>
        );
    };
    return <>{showHtml()}</>;
};

ConnectInfo.propTypes = {};

export default ConnectInfo;

document.querySelectorAll('react-connect-info').forEach((domContainer) => {
    const root = createRoot(domContainer);
    root.render(<ConnectInfo />);
});
