import axiosService from '../../services/axiosService';

const BASE_URL = '/Controller/IC/EInvoice.aspx';
const LIST_URL = {
    EXPORT: `${BASE_URL}/ExportInvoice`,
    UPDATE: `${BASE_URL}/UpdateInvoice`,
};

const eInvoiceApis = {
    doExport(dataPost) {
        return axiosService.post(LIST_URL.EXPORT, dataPost);
    },
    doUpdate(dataPost) {
        return axiosService.post(LIST_URL.UPDATE, dataPost);
    },
};
export default eInvoiceApis;
