import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

export const SWAL = {
    TYPE: {
        WARNING: 'warning',
        ERR: 'error',
        SUCCESS: 'success',
        INFO: 'info',
        QUESTION: 'question',
    },
};
const _alertUtils = {
    /**==========================================
    * Hiển thị sweet alert
    * @param {string} type: loại cảnh báo
    * @param {string} msg: message
    * @param {boolean} isQuesion: có phải loại câu hỏi không
    ==========================================*/
    show(type, msg, isQuestion) {
        if (isQuestion) {
            return MySwal.fire({
                icon: type,
                title: 'Thông báo',
                html: msg,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Đồng ý',
                cancelButtonText: 'Hủy',
                showCancelButton: true,
                allowOutsideClick: false,
                allowEscapeKey: false,
                customClass: {
                    htmlContainer: 'react-css',
                },
            });
        }
        return MySwal.fire({
            icon: type,
            title: 'Thông báo',
            html: msg,
        });
    },
    /** Hiển thị thông báo lựa chọn */
    showQuestion(msg, isQuestion = true) {
        return this.show(SWAL.TYPE.QUESTION, msg, isQuestion);
    },
    showWarning(msg) {
        return this.show(SWAL.TYPE.WARNING, msg, false);
    },
    showText(txt) {
        Swal.fire({
            html: txt,
        });
    },
    showSuccess(msg) {
        return this.show(SWAL.TYPE.SUCCESS, msg);
    },
    showErr(msg) {
        return this.show(SWAL.TYPE.ERR, msg);
    },
    showTimer(type, msg, timer = 1000) {
        return MySwal.fire({
            icon: type,
            title: msg,
            timer: timer,
            timerProgressBar: true,
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            didOpen: () => {
                MySwal.hideLoading();
            },
        });
    },
    showTimerFunc({ type, title, msg, func, timer = 1000 }) {
        let timerInterval;
        MySwal.fire({
            icon: type,
            title: title,
            html: msg,
            timer: timer,
            didOpen: () => {
                timerInterval = setInterval(() => {
                    MySwal.getHtmlContainer().querySelector(
                        'strong'
                    ).textContent = (Swal.getTimerLeft() / 1000).toFixed(0);
                }, 100);
            },
            willClose: () => {
                clearInterval(timerInterval);
                func();
            },
        });
    },
    showInputType(title = 'Thông báo', content, valueConfirm) {
        if (!valueConfirm) {
            valueConfirm = 'tôiđồngý';
        }

        const config = {
            title: title,
            html: `${content} `,
            input: 'text',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Đồng ý',
            cancelButtonText: 'Hủy',
            allowOutsideClick: false,
            preConfirm: (res) => {
                if (!res) {
                    Swal.showValidationMessage(
                        'Bạn chưa nhập! Xin vui lòng kiểm tra lại'
                    );
                } else if (
                    res.replace(/\s+/g, '').toLowerCase() !== valueConfirm
                ) {
                    Swal.showValidationMessage(
                        'Bạn nhập chưa chính xác! Xin vui lòng kiểm tra lại'
                    );
                }
                Swal.enableButtons();
            },
        };
        return MySwal.fire(config);
    },
    showLoading(msg) {
        return MySwal.fire({
            html: msg,
            timerProgressBar: true,
            allowOutsideClick: false,
            didOpen: () => {
                MySwal.showLoading();
            },
        });
    },
    closeLoading() {
        MySwal.close();
    },
};

export default _alertUtils;
