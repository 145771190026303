import React from 'react';
import { FOODKID } from '../../../constants/foodkid.const';
import MenuReportForm from './ReportForm';
import { createRoot } from 'react-dom/client';

const FoodkidMenuReportContainer = () => {
    const isApply = FOODKID.IS_APPLY_REPORT;

    return <>{isApply ? <MenuReportForm /> : null}</>;
};

export default FoodkidMenuReportContainer;

document.querySelectorAll('react-menu-report').forEach((domContainer) => {
    const root = createRoot(domContainer);
    root.render(<FoodkidMenuReportContainer />);
});
