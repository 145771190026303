import React from 'react';
import { FOODKID } from '../../../constants/foodkid.const';
import ReportForm from './ReportForm';
import { createRoot } from 'react-dom/client';

const SoThucAnReportContainer = () => {
    const isApply = FOODKID.IS_APPLY_REPORT;

    return <>{isApply ? <ReportForm /> : null}</>;
};

export default SoThucAnReportContainer;

document
    .querySelectorAll('react-so-tre-thuc-an-report')
    .forEach((domContainer) => {
        const root = createRoot(domContainer);
        root.render(<SoThucAnReportContainer />);
    });
