import axiosService from '../services/axiosService';

const LIST_URL = {
    GET_BANNER: `/Controller/Login.aspx/LayDsBanner`,
};

const loginApis = {
    getBanner(dataPost) {
        return axiosService.post(LIST_URL.GET_BANNER, dataPost);
    },
};
export default loginApis;
