import React, { useEffect, useMemo, useState } from 'react';
import { FOODKID } from '../../../constants/foodkid.const';
import authenUtils from '../../../utils/authen.utils';
import soKiemThucApis from '../../../apis/foodkid/soKiemThuc.api';
import _thongKeBaoCaoPGDHelpers from '../ThongKeBaoCaoPGD/thongKeBaoCaoPGD.helpers';

/**
 * SỔ KIỂM THỰC BA BƯỚC - BÁO CÁO SỞ GIÁO DỤC
 */
const SoKiemThucBaBuocReportForm = () => {
    const [dataPost, setDataPost] = useState();
    const [listData, setListData] = useState([]);
    const [reloadCount, setReloadCount] = useState(0);

    const listNeedReport = useMemo(() => {
        return listData.filter(
            (o) =>
                o.TinhTrangSoBaBuoc !== FOODKID.TRANG_THAI_BAO_CAO.CHUA_LAM &&
                o.TinhTrangSoBaBuoc !== FOODKID.TRANG_THAI_BAO_CAO.DA_BAO_CAO
        );
    }, [listData]);

    const doReset = () => {
        setListData([]);
    };

    const fechData = async () => {
        if (!dataPost) return;
        const { status, data } = await soKiemThucApis.getByDate(
            dataPost?.ngayLap,
            dataPost?.maTruong
        );
        if (status !== 200) {
            doReset();
            return;
        }
        const dataRes = JSON.parse(data.d);
        if (!dataRes.Success) {
            doReset();
            return;
        }

        setListData(dataRes.Data);
    };

    useEffect(() => {
        const elmTrigger = document.getElementById('reactTrigger');
        if (!elmTrigger) return;
        elmTrigger.addEventListener('click', function () {
            const tpbbDate = window.tpbbDate;
            const _count = window.reloadCount;
            if (!tpbbDate) return;
            const matruong = authenUtils.getSchoolId();
            setDataPost({ ngayLap: tpbbDate, maTruong: matruong });
            setReloadCount(_count);
        });
    }, []);

    useEffect(() => {
        console.log(dataPost);
        if (!dataPost) {
            doReset();
            return;
        }
        fechData();
    }, [dataPost]);

    useEffect(() => {
        if (reloadCount) {
            fechData();
        }
    }, [reloadCount]);

    const doReport = async () => {
        if (!listData.length === 0) return null;
        await _thongKeBaoCaoPGDHelpers.sendReport(
            listData[0],
            FOODKID.MAU_BAO_CAO.SO_KIEM_THUC,
            () => {
                fechData();
            }
        );
    };

    const onGetResult = async () => {
        if (!listData.length === 0) return null;

        listData.forEach(async (elm) => {
            await _thongKeBaoCaoPGDHelpers.getResult(
                elm,
                FOODKID.MAU_BAO_CAO.SO_KIEM_THUC,
                () => {
                    fechData();
                }
            );
        });
    };

    const showHtml = () => {
        if (!listData.length === 0) return null;
        const itemReport =
            listNeedReport.length === 0 ? listData[0] : listNeedReport[0];

        return _thongKeBaoCaoPGDHelpers.showStamp(
            itemReport,
            FOODKID.MAU_BAO_CAO.SO_KIEM_THUC,
            () => {
                doReport();
            },
            () => {
                onGetResult();
            }
        );
    };

    return <>{showHtml()}</>;
};

export default SoKiemThucBaBuocReportForm;
