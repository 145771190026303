import React from 'react';
import PropTypes from 'prop-types';

import locale from 'antd/lib/locale/vi_VN';
import 'moment/locale/vi';
import { ConfigProvider } from 'antd';

const AntdLocaleProvider = ({ children }) => {
    return <ConfigProvider locale={locale}>{children}</ConfigProvider>;
};

AntdLocaleProvider.propTypes = {};

export default AntdLocaleProvider;
