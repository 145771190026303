import React from 'react';
import { FOODKID } from '../../../constants/foodkid.const';
import ReportForm from './ReportForm';
import { createRoot } from 'react-dom/client';

const SoKiemThucBaBuocReportContainer = () => {
    const isApply = FOODKID.IS_APPLY_REPORT;

    return <>{isApply ? <ReportForm /> : null}</>;
};

export default SoKiemThucBaBuocReportContainer;

document.querySelectorAll('react-so-ba-buoc-report').forEach((domContainer) => {
    const root = createRoot(domContainer);
    root.render(<SoKiemThucBaBuocReportContainer />);
});
