import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import 'dayjs/locale/vi';
import locale from 'antd/es/date-picker/locale/vi_VN';

const propTypes = {
    onChange: PropTypes.func,
};

const MonthPicker = ({ onChange, title, disableDate }) => {
    const [month, setCurrentMonth] = useState(dayjs(new Date()));

    const onChangeData = (_month) => {
        if (onChange) {
            onChange({
                month: +_month.format('M'),
                year: +_month.format('YYYY'),
            });
        }
    };

    useEffect(() => {
        onChangeData(month);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleDisableDate = (current) => {
        if (!disableDate) return true;
        return current <= disableDate;
    };

    const onHandleChange = (s) => {
        setCurrentMonth(s);
        onChangeData(s);
    };
    return (
        <Fragment>
            {title ? <div className="my-label">{title}</div> : null}

            <DatePicker
                picker="month"
                format="MM/YYYY"
                value={month}
                onChange={(s) => onHandleChange(s)}
                allowClear={false}
                locale={locale}
                disabledDate={handleDisableDate}
            />
        </Fragment>
    );
};

MonthPicker.propTypes = propTypes;

export default MonthPicker;
