import axiosService from '../../services/globalAxiosService';
import { API_SCV1_ENDPOINT } from '../../constants/api.const';

const BASE_URL = `${API_SCV1_ENDPOINT}/v1/KhuyenMai`;
const LIST_URL = {
    GET_LOGIN: `${BASE_URL}/GetItemLogin`,
};

const promotionApis = {
    getLogin() {
        return axiosService.post(LIST_URL.GET_LOGIN, '', 'sc');
    },
};
export default promotionApis;
